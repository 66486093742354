import { configureStore } from "@reduxjs/toolkit";
import paymentReducer from "./payments";
import bookingReducer from './booking'
import stepperReducer from './stepper'
import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  payment: paymentReducer,
  booking: bookingReducer,
  stepper: stepperReducer
});

const persistdReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
  reducer: persistdReducer,
});

export default store;
