export type CarouselImageItem = {
  name?: string;
  src: string;
  text?: string;
};

 type Item = {
  Intent: string;
  paymentSession: string;
  paymentStatus: string;
};

export type LubiaSessions = {
  Item: Item;
};


export type curStep ={
  step: number
}

export type reactDates = {
    startDate: null | moment.Moment;
    endDate: null | moment.Moment;
  };

export type TBooking = {
    clientSecret: string
    sessionId: string;
    startDate: moment.Moment | null
    endDate: moment.Moment | null
    status: 'pending' | 'paid' | 'failed' | null;

  }

export type rangeBooking = {
  startDate : moment.Moment;
  endDate: moment.Moment;
  Compartment: 'A' | 'B' | 'AB'
}

export type successBooking = {
  PK: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  firstName: string,
  lastName: string,
  Email: string,
  Price: string,
  Guests: string,
  Comment?: string,
  Submitted?: boolean,
  agreeRules?: boolean,
  Compartment: null | 'A' | 'B' | 'AB',
  Language: 'nl' | 'fr' | 'en'

}

export type BookingDates = {
  startDate: moment.Moment,
  endDate: moment.Moment
}


export const VillaCarousel: CarouselImageItem[] = [
  {
    name: "Random Name #1",
    src: "/images/cropped-22-1024x615.jpeg",
  },
  { name: "test", src: "/images/1_10-1.jpeg" },
  {
    name: "image",
    src: "/images/1_18-1-1024x768.jpeg",
  },
];

export const BookingCarousel: CarouselImageItem[] = [
  {
    name: "Random Name #1",
    src: "/images/bedroomTest.jpg",
    text: "bedroom 1",
  },
  { name: "test", src: "/images/bedroomTest.jpg", text: "bedroom 2" },
  {
    name: "image",
    src: "/images/bedroomTest.jpg",
    text: "bedroom 3",
  },
  {
    name: "image",
    src: "/images/bedroomTest.jpg",
    text: "bedroom 4",
  },
  {
    name: "image",
    src: "/images/bedroomTest.jpg",
    text: "bedroom 5",
  },
];
