import Stripe from "./booking/stripe";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { DateRangePicker, FocusedInputShape } from "react-dates";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import { getDates } from "../utils/server/getDates";
import {
  BookingCarousel,
  BookingDates,
  reactDates,
  successBooking,
  TBooking,
} from "../config/types";
import { Col, Container, FormGroup, Row, Toast } from "react-bootstrap";
import { bookingInput, dateItemStyle } from "../utils/style";
import ImageCarousel from "./ImageCarousel";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentActions } from "../utils/store/payments";
import { postPayment } from "../utils/server/postPayment";
import { BookingActions } from "../utils/store/booking";
import i18next from "i18next";
import { CheckBox } from "@mui/icons-material";
import BookingForm from "./BookingForm";
import BookingComplete from "./bookingForm/bookingComplete";
import Rooms from "./rooms/Rooms";

function Booking() {
  const dispatch = useDispatch();

  let paymentStatus: TBooking;
  let bookingStatus: successBooking;
  const [startDate, setStartDate] = useState<null | moment.Moment>(null);
  const [endDate, setEndDate] = useState<null | moment.Moment>(null);
  const [price, setPrice] = useState(0);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [reservedDays, setReservedDays] = useState<moment.Moment[]>([]);
  let days: successBooking[] = [];

  function onDateChange(dates: reactDates) {
    setStartDate(dates.startDate);

    setEndDate(dates.endDate);
    if (dates.startDate && dates.endDate) {
      console.log("changeing dates");
      console.log(dates);
      dispatch(PaymentActions.updateDates(dates));
      setPriceHandler(dates.startDate, dates.endDate);
      const res = postPayment(dates.startDate, dates.endDate);
      res.then((data) => {
        console.log("update clientsecret");
        console.log(data.clienSecret);
        dispatch(PaymentActions.updateSecret(data.clienSecret));
      });
    }
  }
  function onFocusChange(focusedInput: any) {
    setFocusedInput(focusedInput);
  }

  function isValidEmail(email: string): boolean {
    // Regular expression pattern for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the input email against the regex pattern
    return emailRegex.test(email);
  }

  paymentStatus = useSelector((state: any) => {
    console.log("here");
    console.log(paymentStatus);
    return state.payment;
  });

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    // this function will be executed only once when the component is rendered
    function showPaymentToast() {
      //showPaymentToastHandler()
    }
    showPaymentToast();
    if (paymentStatus.startDate) {
      console.log("startdate success");
      setStartDate(moment(paymentStatus.startDate));
      setEndDate(moment(paymentStatus.endDate));
    }
  }, []);

  const setPriceHandler = (date1: moment.Moment, date2: moment.Moment) => {
    const dateDiff = date2?.diff(date1, "days") + 1;
    const tempPrice = dateDiff * 450;
    setPrice(tempPrice);
  };

  useEffect(() => {
    async function getDatesLambda() {
      days = await getDates();
    }

    getDatesLambda().then(() => {
      convertStringtoDateTime(days);
    });
  }, []);

  const CheckInput = () => {
    console.log(bookingStatus);
  };

  function convertStringtoDateTime(days: successBooking[]) {
    days.forEach((item: BookingDates) => {
      for (
        let date: moment.Moment = item.startDate;
        date <= item.endDate;
        date.add(1, "days")
      ) {
        // Push the date into the array
        date = moment(date).add(1, "days");
        setReservedDays((reservedDays) => {
          return [...reservedDays, date];
        });
      }
    });
  }

  const renderDayContents = (day: moment.Moment, date: any) => {
    return (
      <div>
        <Row>
          <span>{day.format("DD").toString()}</span>
        </Row>
        <Row>
          <p style={dateItemStyle}>€ 450</p>
        </Row>
      </div>
    );
  };

  const blockedDay = (momentDate: moment.Moment): boolean => {
    const dates: string[] = [];
    reservedDays.forEach((element) => {
      dates.push(element.format("YYYY-MM-DD"));
    });
    if (dates.includes(moment(momentDate).format("YYYY-MM-DD"))) {
      return true;
    }
    return false;
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  console.log(isMobile);
  return (
    <>
      <section>
        <div className="block">
          <div className="textBlock">
            <div>
            <Rooms/>
            </div>

            <br />

            <h1>Maak een boeking</h1>
            <br />
            <BookingForm />
            <br />
          </div>
        </div>
      </section>
    </>
  );
}

export default Booking;
