import axios from 'axios'

axios.defaults.headers.common['x-api-key'] = `9LD6WKyrzU87yCA6ai84T59p82ET1nbJ2i7b0NFV`;

export async function postSession(Intent: string, paymentSession: string, paymentStatus: string){
    const response = await axios.post('https://api.villalubia.com/bookings', {
        "Intent": Intent,
        "paymentSession": paymentSession,
        "paymentStatus": paymentStatus
    })
    return response.data
}