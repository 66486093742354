import {createSlice } from '@reduxjs/toolkit'
import { successBooking } from '../../config/types'
import moment, { Moment } from "moment";
import { Satellite } from '@mui/icons-material';

const initialFirstTimeState: successBooking= {PK: '', startDate: moment(), endDate: moment(),firstName: '', lastName: '', Email: '', Price: "0", Guests: "1", Comment: '', Submitted: false, agreeRules: false, Compartment: null, Language: 'en' }
const bookingSlice = createSlice({
    name: 'Booking',
    initialState: initialFirstTimeState,
    reducers: {
        updateFirstname(state, actions){
            state.firstName = actions.payload
        },
        updateLastname(state, actions){
            state.lastName = actions.payload
        },
        updateEmail(state, actions){
            state.Email = actions.payload
        },
        updatePrice(state, actions){
            state.Price = actions.payload
        },
        updateGuests(state, actions){
            state.Guests = actions.payload
        },
        updateComment(state, actions){
            state.Comment = actions.payload
        },
        updateSubmitted(state, actions){
            state.Submitted = actions.payload
        },
        updateAgreeRules(state, actions){
            console.log('datb')
            state.agreeRules = actions.payload
        },
        updateCompartment(state,actions){
            state.Compartment = actions.payload
        },
        updateDates(state, actions){
            state.startDate = actions.payload.startDate
            state.endDate = actions.payload.endDate
        },
        updateLanguage(state, actions){
            state.Language = actions.payload
        }


    }
})

export const BookingActions = bookingSlice.actions

export default bookingSlice.reducer;
