import i18next from "i18next";

function houseRules() {
  return (
    <div>
      <div className="block">
        <div className="textBlock">
          <h1>{i18next.t("TXT027")}</h1>
          <p>
            Wij hanteren een aantal regels. Alle bezoeker moeten het contract
            gelezen hebben en akkoord gaan. Bij schending van het contract kan
            een schadevergoeding gevraagd worden.
          </p>

          <p>Bekijk zeker volgende <a href="./assets/pdf/Test.pdf">PDF</a></p>

          <p>Bij vragen kan je ons altijd contacteren via Whatsapp.</p>

        </div>
      </div>
    </div>
  );
}

export default houseRules;
