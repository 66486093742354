import "./App.css";
import OverOnzeVilla from "./componenets/OverOnzeVilla";
import Location from "./componenets/Location";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/OverOnsHover.css";
import OverOns from "./componenets/OverOns";
import Booking from "./componenets/Booking";
import OurFacilities from "./componenets/OurFacilities";
import { useEffect, useReducer, useRef, useState } from "react";
import Contact from "./componenets/Contact";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "./utils/store/payments";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Dropdown from "./componenets/dropdown";
import HouseRules from "./componenets/houseRules";
import { ToastContainer } from "react-toastify";
import { BookingActions } from "./utils/store/booking";
import BookingTBD from "./componenets/booking/BookingTBD";
export const cookies = new Cookies();

function App() {
  const value_or_null = (document.cookie.match(
    /^(?:.*;)?\s*dev\s*=\s*([^;]+)(?:.*)?$/
  ) || [, null])[1];

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [languageChanged, setLanguageChanged] = useState(false);
  const [tReady, setTReady] = useState(false);

  useEffect(() => {
    i18next
      .use(LanguageDetector)
      .use(HttpApi)
      .init(
        {
          debug: true,
          fallbackLng: "en",
          detection: {
            order: [
              "cookie",
              "htmlTag",
              "querystring",
              "localStorage",
              "sessionStorage",
              "navigator",
              "path",
              "subdomain",
            ],
            caches: ["cookie"],
          },
          backend: {
            loadPath: "assets/locales/{{lng}}/translations.json",
          },
          react: { useSuspense: false },
        },
        () => {
          setTReady(true);
        }
      );
  }, []);

  const dispatch = useDispatch();

  const ref = useRef<null | HTMLDivElement>(null);

  const handleClick = () => {
    console.log("received");
    ref.current?.scrollIntoView({ behavior: "auto" });
  };

  cookies.set("SessionId", uuidv4(), {
    path: "/",
    httpOnly: false,
    secure: true,
  });

  const sessionId = useSelector((state: any) => {
    return state.payment.sessionId;
  });

  useEffect(() => {
    if (sessionId) {
      console.log("sessionId found");
    } else {
      dispatch(PaymentActions.updateSessionId(uuidv4()));
    }
    // this function will be executed only once when the component is rendered
  }, []); // the empty array ensures that the function is only executed on the initial render

  useEffect(() => {
    if (languageChanged) {
      setLanguageChanged(false);
      forceUpdate();
    } else {
      forceUpdate();
    }
    // this function will be executed only once when the component is rendered
  }, [languageChanged]); // the empty array ensures that the function is only executed on the initial render

  const handleSelect = (e: any) => {
    setLanguageChanged(true);
    console.log(e);
    i18next.changeLanguage(e);
    dispatch(BookingActions.updateLanguage(e));
    window.location.reload();
  };

  return tReady ? (
    <div id="parallax-world-of-ugg">
      <Dropdown onLanguageChange={handleSelect}></Dropdown>
      <section>
        <div className="title">
          <h3>VILLA LUBIA</h3>
          <br></br>
          <p className="line-break margin-top-10"></p>
        </div>
      </section>

      <section>
        <div className="parallax-one"></div>
      </section>

      <OverOnzeVilla onClick={handleClick} />

      <section>
        <div className="parallax-two">
          <h2>ESPANA</h2>
        </div>
      </section>

      <Location />

      <section>
        <div className="parallax-three">
          <h2>Booking</h2>
        </div>
      </section>

      <>
        {value_or_null == "dev" ? (
          <Booking />
        ) : (
          <div>
            <p>
              <BookingTBD></BookingTBD>
            </p>
          </div>
        )}
      </>

      <section>
        <div className="parallax-three">
          <h2>LUC & Bianca</h2>
        </div>
      </section>
      <OverOns />
      <Contact />
      <section>
        <div className="parallax-one">
          <h2>{i18next.t("TXT022")}</h2>
        </div>
      </section>
      <div ref={ref}></div>
      <OurFacilities />
      <section>
        <div className="parallax-one">
          <h2>{i18next.t("TXT026")}</h2>
        </div>
      </section>
      <HouseRules></HouseRules>
      <ToastContainer position="bottom-right" />
    </div>
  ) : null;
}

export default App;
