import { Card, CardContent } from "@mui/material";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { Col, Container, Row } from "react-bootstrap";
import i18next from "i18next";


function Location() {


  return(
    <>

      <section>

        <div className="block">
          <div className="textBlock">

          <h1>{i18next.t("TXT012")}</h1>

            <p>
            {/* <span className="first-character sc">S</span> */}
              {i18next.t("TXT001")}
            </p>

            <p>
              {i18next.t("TXT002")}
            </p>
          </div>
          <Container>
            <Row>
              <Col>
                <Map
                  height={500}
                  defaultCenter={[38.119907, -0.724738]}
                  defaultZoom={12}
                >
                  <Marker width={30} anchor={[38.119907, -0.724738]} />
                  <ZoomControl />
                </Map>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#E5E4E2" }}>
                  <CardContent>
                    <p>
                      Lokatie: Adres: Vereda Barraquele, 9, 03177 San Fulgencio,
                      Alicante, Spanje
                    </p>
                    <a href="">Google maps</a>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  )
}

export default Location;
