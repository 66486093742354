import {createSlice } from '@reduxjs/toolkit'
import { curStep } from '../../config/types'

const initialStep: curStep= {step:0,}
const stepperSlice = createSlice({
    name: 'Stepper',
    initialState: initialStep,
    reducers: {
        updateStep(state, actions){
            console.log(state.step)
            console.log(actions.payload)
            state.step = actions.payload
        },
    }
})

export const StepperActions = stepperSlice.actions

export default stepperSlice.reducer;
