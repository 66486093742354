import axios from "axios";
import moment from "moment";
import { successBooking } from "../../../config/types";

axios.defaults.headers.common[
  "x-api-key"
] = `Ck2zeFBZsr53hg8STSFzP32pu7fopte74hf2TdKD`;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,OPTIONS,POST";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"

// Post to get paymentIntent
export async function postBooking(booking: successBooking) {
  console.log(booking);

  // Find the index of the "_secret_" substring
  const secretIndex = booking.PK.indexOf("_secret_");

  // If "_secret_" is found, extract the substring before it; otherwise, return the original string
  const result =
    secretIndex !== -1 ? booking.PK.substring(0, secretIndex) : booking.PK;
  const response = await axios.post("https://api.villalubia.com/dates", {
    PK: result,
    startDate: moment(booking.startDate).format("YYYY-MM-DD"),
    endDate: moment(booking.endDate).format("YYYY-MM-DD"),
    firstName: booking.firstName,
    lastName: booking.lastName,
    Email: booking.Email,
    Price: booking.Price,
    Guests: booking.Guests,
    Comment: booking.Comment,
    Compartment: booking.Compartment,
    Language: booking.Language,
  });
  console.log(response.data);
  return response.data;
}
