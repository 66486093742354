export type FacilityItems = {
  name: string;
  id: number;
  img: string;
};

export const FacilityData: FacilityItems[] = [
  {
    id: 1,
    name: "Omheinde tuin",
    img: "/images/unnamed-1-2.jpeg",
  },
  { id: 2, name: "500m terras", img: "/images/unnamed-1-2.jpeg" },
  {
    id: 3,
    name: "10 parkeerplaatsen",
    img: "/images/unnamed-1-2.jpeg",
  },
];
