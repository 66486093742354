import i18next from "i18next";
import { Container, Row, Col } from "react-bootstrap";
import { BookingCarousel } from "../../config/types";
import ImageCarousel from "../ImageCarousel";
import Stack from "@mui/material/Stack/Stack";
import { Divider } from "@mui/material";

function Rooms() {
  return (
    <>
      <Container>
        <h1>{i18next.t("TXT013")}</h1>
        <p>{i18next.t("TXT032")}</p>

        <Row>
          <h4>Blok A</h4>

          <Col style={{ paddingBottom: 20 }}>
            <br></br>
            <div>
              <p style={{ margin: 0 }}>
                Bedroom 1::2 single beds and1 large double bed
              </p>
              <p style={{ margin: 0 }}>Bedroom 2::2 single beds</p>
              <p style={{ margin: 0 }}>Bedroom 3::1 large double bed</p>
              <p style={{ margin: 0 }}>Bedroom 4::2 single beds</p>
              <p style={{ margin: 0 }}>Bedroom 5::2 single beds</p>
            </div>
          </Col>
          <Col className="mobileCarousel">
            <ImageCarousel
              CarouselItems={BookingCarousel}
              width={580}
              height={240}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col>
            {" "}
            <h4>Blok B</h4>
          </Col>
        </Row>
        <Row>
          <Col className="mobileCarousel">
            <ImageCarousel
              CarouselItems={BookingCarousel}
              width={580}
              height={240}
            />
          </Col>
          <Col style={{ paddingBottom: 20 }}>
            <br></br>
            <div>
              <p style={{ margin: 0 }}>
                Bedroom 1::2 single beds and1 large double bed
              </p>
              <p style={{ margin: 0 }}>Bedroom 2::2 single beds</p>
              <p style={{ margin: 0 }}>Bedroom 3::1 large double bed</p>
              <p style={{ margin: 0 }}>Bedroom 4::2 single beds</p>
              <p style={{ margin: 0 }}>Bedroom 5::2 single beds</p>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </Row>
      </Container>
    </>
  );
}

export default Rooms;
