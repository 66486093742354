import axios from 'axios'

axios.defaults.headers.common['x-api-key'] = `9LD6WKyrzU87yCA6ai84T59p82ET1nbJ2i7b0NFV`;

export async function getSession(Intent: string){
    console.log(`clientSecret: ${Intent}`)
    const response = await axios.get('https://api.villalubia.com/bookings', {params: {intent: Intent}})
    console.log(response)
    return response.data
}

