import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "../../utils/store/payments";
import moment, { Moment } from "moment";
import { ToastContainer } from "react-bootstrap";

interface stripeSecret {
  clienSecret: string;
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51LsZFiFuJOVa2z2VEBvaNcoCJn5P6rutaU9kW4qawc8xsA3gq6iJ6WHRQzWDTbzSgFwQUqik2MAC2eWt29p4imJE00mlMHWOEq"
);

function Stripe({
  startDate,
  endDate
}: {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;

}) {
  const dispatch = useDispatch();

  const clientSecret: string = useSelector((state: any) => {
    console.log('clientSecret')
    return state.payment.clientSecret;
  });

  


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (startDate && endDate) {
      if(new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret")){
          console.log('intent found')
        }
        else{
          // const res = postPayment(startDate, endDate);
          // res.then((data) => {
          //   console.log('update clientsecret')
          //   console.log(data.clienSecret);
          //   dispatch(PaymentActions.updateSecret(data.clienSecret));
          // });
        }

    }
    // This should be dynamic, it should create a payment intent only when all the required fields has values
  }, [startDate, endDate]);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default Stripe;
