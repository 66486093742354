import { Container, Row, Col } from "react-bootstrap";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { bookingInput, dateItemStyle } from "../../utils/style";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  TBooking,
  successBooking,
  reactDates,
  BookingDates,
  rangeBooking,
} from "../../config/types";
import { getDates } from "../../utils/server/getDates";
import { postPayment } from "../../utils/server/postPayment";
import { PaymentActions } from "../../utils/store/payments";
import { BookingActions } from "../../utils/store/booking";
import { toast } from "react-toastify";

function BookingStep2({isFunctionExecuted}: {isFunctionExecuted:boolean}) {
  const dispatch = useDispatch();

  let compartmentABookings: rangeBooking[];
  let paymentStatus: TBooking;
  let bookingStatus: successBooking;
  const [startDate, setStartDate] = useState<null | moment.Moment>(null);
  const [endDate, setEndDate] = useState<null | moment.Moment>(null);

  const [daysBetween, setDaysBetween] = useState<string[]>([]);
  const [daysBetweenToPick, setdaysBetweenToPick] = useState<string[]>([]);

  const [temp, setCompartmentBooking] = useState<rangeBooking[]>();
  const [price, setPrice] = useState(0);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [reservedDays, setReservedDays] = useState<moment.Moment[]>([]);
  let [days, setDays] = useState<rangeBooking[]>();


  useEffect(() => {
    if (isFunctionExecuted) {
      // Perform actions or logic when the function is executed in the parent
      console.log("Function executed in the parent");
      setStartDate(null)
      setEndDate(null)
      dispatch(BookingActions.updateDates({"startDate": null, "endDate": null}));
      dispatch(PaymentActions.updateDates({"startDate": null, "endDate": null}));
    }
  }, [isFunctionExecuted]);


  function onDateChange(dates: reactDates) {
    setStartDate(dates.startDate);

    setEndDate(dates.endDate);
    if (dates.startDate && dates.endDate) {
      console.log(daysBetween);
      const a = moment(dates.startDate).format("YYYY-MM-DD");

      const startDate = moment(dates.startDate, "YYYY-MM-DD");
      const endDate = moment(dates.endDate, "YYYY-MM-DD");

      let day = startDate;

      
      while (day <= endDate) {
        daysBetweenToPick.push(day.format("YYYY-MM-DD"));
        day = day.add(1, "day");
      }
      console.log(daysBetweenToPick);
      let isDateInUnavailableRange = false;
      daysBetweenToPick.forEach((day) => {
        if (daysBetween.includes(day)) {
          isDateInUnavailableRange = true;
        }
      });

      if (isDateInUnavailableRange) {
        toast.error("datums mogen elkaar niet overschrijven");
        setStartDate(null);
        setEndDate(null);
        dispatch(BookingActions.updateDates({"startDate": null, "endDate": null}));
        dispatch(PaymentActions.updateDates({"startDate": null, "endDate": null}));
        dispatch(BookingActions.updatePrice('0'))
      }

      console.log("days between");
      console.log(daysBetween);

      daysBetweenToPick.splice(0, daysBetweenToPick.length);

      if (daysBetween.includes(moment(dates.startDate).format("YYYY-MM-DD"))) {
      }
      if(!isDateInUnavailableRange){
        dispatch(PaymentActions.updateDates(dates));
        dispatch(BookingActions.updateDates(dates));
        setPriceHandler(dates.startDate, dates.endDate);

      }

      const res = postPayment(dates.startDate, dates.endDate);
      res.then((data) => {
        console.log("update clientsecret");
        console.log(data.clienSecret);
        dispatch(PaymentActions.updateSecret(data.clienSecret));

      });
    }
  }
  function onFocusChange(focusedInput: any) {
    setFocusedInput(focusedInput);
  }

  paymentStatus = useSelector((state: any) => {
    return state.payment;
  });

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    // this function will be executed only once when the component is rendered
    function showPaymentToast() {
      //showPaymentToastHandler()
    }
    showPaymentToast();
    if (paymentStatus.startDate) {
      console.log("startdate success");
      setStartDate(moment(paymentStatus.startDate));
      setEndDate(moment(paymentStatus.endDate));
    }
  }, []);

  const setPriceHandler = (date1: moment.Moment, date2: moment.Moment) => {
    const dateDiff = date2?.diff(date1, "days") + 1;
    const tempPrice = dateDiff * 450;
    dispatch(BookingActions.updatePrice(tempPrice.toString()));
    setPrice(tempPrice);
  };

  useEffect(() => {
    async function getDatesLambda() {
      const data = await getDates();
      setDays(data);
      return data;
    }
    async function fetchData() {
      const t = await getDatesLambda();
      console.log(t);
      console.log(days);
      if (t) {
        return convertStringtoDateTime(t);
      }
    }
    fetchData();
  }, []);

  function convertStringtoDateTime(days: rangeBooking[]) {
    if (bookingStatus.Compartment === "AB") {
      compartmentABookings = days;
      setCompartmentBooking(compartmentABookings);
    } else {
      compartmentABookings = days.filter(
        (day) => {
          return day.Compartment === bookingStatus.Compartment || day.Compartment === "AB";
        }
      ); // error here
      setCompartmentBooking(compartmentABookings);
    }
    console.log(compartmentABookings);
    console.log(days);

    compartmentABookings.forEach((item: rangeBooking) => {
      const startDate = moment(item.startDate, "YYYY-MM-DD");
      const endDate = moment(item.endDate, "YYYY-MM-DD");

      let day = startDate;

      while (day <= endDate) {
        daysBetween.push(day.format("YYYY-MM-DD"));
        setReservedDays((reservedDays) => {
          return [...reservedDays, day];
        });
        day = day.add(1, "day");
      }

      console.log("days between");
      console.log(daysBetween);
    });
  }

  const renderDayContents = (day: moment.Moment, date: any) => {
    return (
      <div>
        <Row>
          <span>{day.format("DD").toString()}</span>
        </Row>
        <Row>
          <p style={dateItemStyle}>€ 450</p>
        </Row>
      </div>
    );
  };

  const blockedDay = (momentDate: moment.Moment): boolean => {
    const dates: string[] = [];
    const curDate = moment(momentDate).format("YYYY-MM-DD");
  
    daysBetween.forEach((element) => {
      dates.push(element);
    });
    if (dates.includes(curDate)) {
      if (days) {

        return true; 
      } 
    }
  
    return false; // Return false if the initial if condition is not met
  };
  

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  console.log(isMobile);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            {isMobile ? (
              <DateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                numberOfMonths={1}
                onDatesChange={onDateChange}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                isDayBlocked={blockedDay}
                hideKeyboardShortcutsPanel
                renderDayContents={renderDayContents}
              />
            ) : (
              <DateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                numberOfMonths={3}
                onDatesChange={onDateChange}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                isDayBlocked={blockedDay}
                openDirection="down"
                hideKeyboardShortcutsPanel
                renderDayContents={renderDayContents}
              />
            )}
          </Col>
          <Col>
            <p>Prijs: {bookingStatus.Price == '0' ? <></> : <p>{bookingStatus.Price} €</p>} </p>
          </Col>

          <br />
          <div style={{ height: 20 }}></div>
        </Row>
      </Container>
    </div>
  );
}

export default BookingStep2;
