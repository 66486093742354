import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { bookingInput } from "../utils/style";

function Contact() {
  return (
    <section>
      <div className="block" style={{ paddingTop: 5 }}>
        <div className="textBlock">
          <h1>Contact</h1>

          <Container>
            <Row>
              <FormControl>
                <Row>
                  <Col>
                    <TextField
                      fullWidth
                      helperText=" "
                      id="demo-helper-text-aligned-no-helper"
                      label="Naam"
                      style={bookingInput}
                    />
                  </Col>
                  <Col>
                    <TextField
                      fullWidth
                      helperText=" "
                      id="demo-helper-text-aligned-no-helper"
                      label="Achternaam"
                      style={bookingInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      helperText=" "
                      id="demo-helper-text-aligned-no-helper"
                      label="Email"
                      fullWidth
                      style={bookingInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      helperText=" "
                      id="demo-helper-text-aligned-no-helper"
                      label="Vraag"
                      multiline
                      fullWidth
                      minRows={3}
                      style={bookingInput}
                    />
                  </Col>
                </Row>
              </FormControl>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Contact;

const styles = {};
