import { FacilityData } from "../utils/FacilitiesItems";
import Facilities from "./overonzevilla/Facilities";
import "../utils/OverOnsHover.css";
import "../utils/animation";
import i18next from "i18next";

function OurFacilities() {
  return (
    <section>
      <div className="block">
        <div className="textBlock">
          <h1>{i18next.t('TXT004')}</h1>
          <p>
            <span className="first-character sc">B</span>{i18next.t('TXT002')}
          </p>
          <br></br>
          <p>{i18next.t('TXT003')}</p>

          <ul>
            <li></li>
          </ul>
        </div>
        <div>
          <nav>
            <ul>
              <Facilities items={FacilityData} />
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default OurFacilities;
