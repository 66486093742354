import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { BookingItems } from "../../classes/BookingItem";
import { successBooking } from "../../config/types";
import { useDispatch } from "react-redux";
import { BookingActions } from "../../utils/store/booking";
import { useReducer } from "react";
import { PaymentActions } from "../../utils/store/payments";

function BookingStep1({ onClick, updateCompartment }: { onClick: () => void, updateCompartment: (compartment: 'A' | 'B' | 'AB') => void, }) {
  const dispatch = useDispatch();


  dispatch(BookingActions.updateDates({"startDate": null, "endDate": null}));
  dispatch(PaymentActions.updateDates({"startDate": null, "endDate": null}));
  dispatch(BookingActions.updatePrice("0"));

  return (
    <List>
      {BookingItems.map((item) => {
        return (
          <ListItem disablePadding key={item.compartment}>
            <ListItemButton
              onClick={() => {
                dispatch(BookingActions.updateCompartment(item.compartment))
                updateCompartment(item.compartment)
                onClick();

              }}
            >
              <ListItemText primary={item.text}></ListItemText>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default BookingStep1;
