import i18next from "i18next";

function OverOns() {
  return (
    <>
      <section>
        <div className="block" style={{ paddingBottom: 0 }}>
          <div className="textBlock">
            <h1>Over ons</h1>

            <p>
              {/* <span className="first-character sc">W</span> */}
              {i18next.t("TXT005")}
            </p>

            <p>{i18next.t("TXT006")}</p>

            <p>
              {i18next.t("TXT007")}
            </p>
            <p>{i18next.t("TXT008")}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default OverOns;
