import axios from 'axios'
import moment from 'moment';

interface stripeSecret {
    clienSecret: string
  }

axios.defaults.headers.common['x-api-key'] = `Ck2zeFBZsr53hg8STSFzP32pu7fopte74hf2TdKD`;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,OPTIONS,POST";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"

// Post to get paymentIntent
export async function postPayment(beginDate:moment.Moment, endDate:moment.Moment): Promise<stripeSecret>{
    console.log(endDate.diff(beginDate, 'days'))
    const response = await axios.post('https://api.villalubia.com/payment', {
        "startDate": beginDate.format('YYYY-MM-DD'),
        "endDate" : endDate.format('YYYY-MM-DD')

    })
    return response.data
}   