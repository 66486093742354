import { BsChevronDoubleDown } from "react-icons/bs";
import ImageCarousel from "./ImageCarousel";
import "../utils/OverOnsHover.css";
import "../utils/animation";
import Facilities from "./overonzevilla/Facilities";
import { Box, Button } from "@mui/material";
import { useRef } from "react";
import { VillaCarousel } from "../config/types";
import i18next from "i18next";

function OverOnzeVilla({onClick}: {onClick: any}) {


  return (
    <section>
      <div className="block">
        <div>
          <div className="textBlock">
          <h1>{i18next.t('TXT010')}</h1>
          <p>
          {/* <span className="first-character sc">V</span> */}
            {i18next.t('TXT009')}
          </p>
          <p className="line-break margin-top-10"></p>
          <p className="margin-top-10">
          {i18next.t('TXT011')}
          </p>
          </div>
        </div>
        <div className="carousel">
          <ImageCarousel CarouselItems={VillaCarousel} />
        </div>
        <div>
          <Box textAlign="center">
            <p>
              {" "}
              <BsChevronDoubleDown style={{height: '2rem', width: '2rem'}}/>
            </p>
            <Button onClick={onClick} variant="outlined">{i18next.t('TXT004')}</Button>
          </Box>
        </div>
      </div>
    </section>
  );
}

export default OverOnzeVilla;
