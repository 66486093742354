import { margin } from "@mui/system";

export const dateItemStyle: React.CSSProperties = {
    fontSize:11,
    padding: 0,
    margin: 0
}

export const bookingInput: React.CSSProperties = {
    zIndex: 0,
    position: 'relative',
}