import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "../../utils/store/payments";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import Cookies from "universal-cookie";
import { getSession } from "../../utils/server/session/getSession";
import { LubiaSessions, successBooking, TBooking } from "../../config/types";
import { putSession } from "../../utils/server/session/putSession";
import { postSession } from "../../utils/server/session/postSession";
import { postBooking } from "../../utils/server/bookings/postBookings";

import Confetti from "react-confetti";



export default function CheckoutForm() {
  //aws
  const dynamoDb = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1" });
  const TABLE_NAME = "villalubia-prod-bookings";

  let bookingStatus: successBooking;

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let clientSecret: string;
  let persitentClientSecret: TBooking;
  let paymentSession: string;

  // const clientSecret: string = useSelector((state: any) => {
  //   return state.payment.clientSecret;
  // });

  const sessionId = useSelector((state: any) => {
    console.log(`sessionId ${state.sessionId}`);
    return state.payment.clientSecret;
  });

  persitentClientSecret = useSelector((state: any) => {
    console.log(state);
    return state.payment;
  });

  useEffect(() => {
    const test = () => {
      console.log("date has been changed");
    };
    test();
    // change clientSecret when dates change. //TODO
  }, [persitentClientSecret]);

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const temp = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (temp) {
      clientSecret = temp;
    }

    console.log(clientSecret);
    console.log(persitentClientSecret);
    if (!clientSecret) {
      return;
    }

    const getIntentStatus = async () => {
      const status: LubiaSessions = await getSession(sessionId);
      return status;
    };

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent)
        switch (paymentIntent.status) {
          case "succeeded":
            (async () => {            
              console.log('payment succedded')

              const session = await getIntentStatus();
              if (session.Item.paymentStatus == "processing" && "failed") {
                console.log("updating status");
                putSession(
                  session.Item.Intent,
                  session.Item.paymentSession,
                  "succeeded"
                );
                console.log(persitentClientSecret);
                if (
                  persitentClientSecret.startDate &&
                  persitentClientSecret.endDate
                ) {
                  console.log("submitting post");

                  postBooking({
                    PK: persitentClientSecret.clientSecret,
                    startDate: persitentClientSecret.startDate,
                    endDate: persitentClientSecret.endDate,
                    firstName: bookingStatus.firstName,
                    lastName: bookingStatus.lastName,
                    Email: bookingStatus.Email,
                    Price: bookingStatus.Price,
                    Guests: bookingStatus.Guests,
                    Comment: bookingStatus.Comment,
                    Compartment: bookingStatus.Compartment,
                    Language: bookingStatus.Language
                  });
                }
              }
            })();
            dispatch(PaymentActions.updatePaymentStatus("paid")); 
            toast.success("booking has been accepted", {
              position: "bottom-right",
              autoClose: 5000,
            });
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            (async () => {
              console.log("session");
              const session = await getIntentStatus();
              console.log(session);
              if (session.Item.paymentStatus != "failed") {
                putSession(
                  session.Item.Intent,
                  session.Item.paymentSession,
                  "failed"
                );
              }
            })();
            toast.error("booking has failed", {
              position: "bottom-right",
              autoClose: 5000,
            });
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {

    if (bookingStatus.firstName && bookingStatus.lastName && bookingStatus.startDate && bookingStatus.Email && bookingStatus.Guests && bookingStatus.agreeRules) {
      postSession(
        persitentClientSecret.clientSecret,
        persitentClientSecret.sessionId,
        "processing"
      );

      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error }: { error: StripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "https://" + window.location.hostname,
          // return_url: "127.0.0.1:3000"
          
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        if (error.message) setMessage(error.message);
      } else {
        console.log(error);
        if (error.payment_intent?.status === "succeeded") {
          toast.success(
            "booking has already been succeeded, check your mail or contact us",
            {
              position: "bottom-right",
              autoClose: 5000,
            }
          );
          setMessage(
            "booking has already been succeeded, check your mail or contact us"
          );
        } else {
          setMessage("An unexpected error occurred.");
        }
      }

      setIsLoading(false);
    }
    else{

      toast.error("Please enter provide the required fields", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }

  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      <LoadingButton
        onClick={handleSubmit}
        loading={isLoading}
        loadingIndicator="Loading…"
        variant="outlined"
      >
        Pay Now
      </LoadingButton>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message">
          <div>
            <p>Bedankt om lid te worden van De Stuivers</p>
            <Confetti></Confetti>
          </div>
          <div></div>
          {message}
        </div>
      )}
    </form>
  );
}
