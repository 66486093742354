import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { Row, Col, ToastContainer } from "react-bootstrap";
import Stripe from "../booking/stripe";
import {
  BookingCarousel,
  BookingDates,
  TBooking,
  reactDates,
  successBooking,
} from "../../config/types";
import { BookingActions } from "../../utils/store/booking";
import { bookingInput, dateItemStyle } from "../../utils/style";
import BookingForm from "../BookingForm";
import ImageCarousel from "../ImageCarousel";
import moment from "moment";
import { useState, useEffect } from "react";
import { FocusedInputShape } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { getDates } from "../../utils/server/getDates";
import { postPayment } from "../../utils/server/postPayment";
import { PaymentActions } from "../../utils/store/payments";

function BookingStep3() {
  const dispatch = useDispatch();

  let paymentStatus: TBooking;
  let bookingStatus: successBooking;
  const [startDate, setStartDate] = useState<null | moment.Moment>(null);
  const [endDate, setEndDate] = useState<null | moment.Moment>(null);
  const [price, setPrice] = useState(0);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [reservedDays, setReservedDays] = useState<moment.Moment[]>([]);
  let days: successBooking[] = [];

  function onDateChange(dates: reactDates) {
    setStartDate(dates.startDate);

    setEndDate(dates.endDate);
    if (dates.startDate && dates.endDate) {
      console.log("changeing dates");
      console.log(dates);
      dispatch(PaymentActions.updateDates(dates));
      setPriceHandler(dates.startDate, dates.endDate);
      const res = postPayment(dates.startDate, dates.endDate);
      res.then((data) => {
        console.log("update clientsecret");
        console.log(data.clienSecret);
        dispatch(PaymentActions.updateSecret(data.clienSecret));
      });
    }
  }
  function onFocusChange(focusedInput: any) {
    setFocusedInput(focusedInput);
  }

  function isValidEmail(email: string): boolean {
    // Regular expression pattern for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the input email against the regex pattern
    return emailRegex.test(email);
  }

  paymentStatus = useSelector((state: any) => {
    console.log("here");
    console.log(paymentStatus);
    return state.payment;
  });

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    // this function will be executed only once when the component is rendered
    function showPaymentToast() {
      //showPaymentToastHandler()
    }
    showPaymentToast();
    if (paymentStatus.startDate) {
      console.log("startdate success");
      setStartDate(moment(paymentStatus.startDate));
      setEndDate(moment(paymentStatus.endDate));
    }
  }, []);

  const setPriceHandler = (date1: moment.Moment, date2: moment.Moment) => {
    const dateDiff = date2?.diff(date1, "days") + 1;
    const tempPrice = dateDiff * 450;
    setPrice(tempPrice);
  };

  useEffect(() => {
    async function getDatesLambda() {
      days = await getDates();
    }

    getDatesLambda().then(() => {
      convertStringtoDateTime(days);
    });
  }, []);

  const CheckInput = () => {
    console.log(bookingStatus);
  };

  function convertStringtoDateTime(days: successBooking[]) {
    days.forEach((item: BookingDates) => {
      for (
        let date: moment.Moment = item.startDate;
        date <= item.endDate;
        date.add(1, "days")
      ) {
        // Push the date into the array
        date = moment(date).add(1, "days");
        setReservedDays((reservedDays) => {
          return [...reservedDays, date];
        });
      }
    });
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  console.log(isMobile);
  return (
    <div>
      <br />
      <h1>{i18next.t("TXT014")}</h1>
      <br />
      <p>{i18next.t("TXT015")}</p>
      <Container>
        <Row>
          <Col>
            <TextField
              name="Voornaam"
              onChange={(e) => {
                dispatch(BookingActions.updateFirstname(e.target.value));
              }}
              id="Voornaam"
              error={!bookingStatus.firstName && bookingStatus.Submitted}
              fullWidth
              helperText=" "
              label={<>{i18next.t("TXT017")}</>}
              required
              style={bookingInput}
              value={bookingStatus.firstName}
            />
          </Col>
          <Col>
            <TextField
              onChange={(e) => {
                dispatch(BookingActions.updateLastname(e.target.value));
              }}
              fullWidth
              required
              error={!bookingStatus.lastName && bookingStatus.Submitted}
              helperText=""
              id="demo-helper-text-aligned-no-helper"
              label={<>{i18next.t("TXT018")}</>}
              style={bookingInput}
              value={bookingStatus.lastName}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <TextField
              helperText=" "
              onChange={(e) => {
                dispatch(BookingActions.updateEmail(e.target.value));
              }}
              id="demo-helper-text-aligned-no-helper"
              label="Email"
              fullWidth
              error={
                !isValidEmail(bookingStatus.Email) && bookingStatus.Submitted
              }
              required
              style={bookingInput}
              value={bookingStatus.Email}
            />
          </Col>
          <Col>
            <TextField
              type="number"
              onChange={(e) => {
                dispatch(BookingActions.updateGuests(Number(e.target.value)));
              }}
              InputProps={{
                inputProps: {
                  max: 12,
                  min: 1,
                },
              }}
              error={
                !(
                  Number(bookingStatus.Guests) > 1 &&
                  Number(bookingStatus.Guests) < 13
                ) && bookingStatus.Submitted
              }
              fullWidth
              required
              label={<>{i18next.t("TXT019")}</>}
              style={bookingInput}
              value={bookingStatus.Guests}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextField
              style={bookingInput}
              helperText={<>{i18next.t("TXT021")}</>}
              onChange={(e) => {
                dispatch(BookingActions.updateComment(e.target.value));
              }}
              id="demo-helper-text-aligned-no-helper"
              label={<>{i18next.t("TXT020")}</>}
              multiline
              fullWidth
              minRows={3}
              value={bookingStatus.Comment}
            />
          </Col>
        </Row>
        <br></br>
      </Container>
      <FormControl
        required={true}
        error={!bookingStatus.agreeRules && bookingStatus.Submitted}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  dispatch(BookingActions.updateAgreeRules(e.target.checked));
                }}
              />
            }
            label={<p>Ik heb de <a href="./assets/pdf/Test.pdf">huisregels</a> gelezen en ben hiermee akkoord</p>}
          />
        </FormGroup>
        <FormHelperText>
          {!bookingStatus.agreeRules && bookingStatus.Submitted ? (
            <p>Gelieve de regels te accepteren</p>
          ) : (
            <></>
          )}{" "}
        </FormHelperText>
      </FormControl>
      {/* {startDate && endDate ? <Stripe startDate={startDate} endDate={endDate} /> :  <Stripe startDate={null} endDate={null} />} */}
      {/* <Stripe startDate={startDate} endDate={endDate} onCheckout={CheckInput} />*/}
      <br />
    </div>
  );
}

export default BookingStep3;
