import Rooms from "../rooms/Rooms"

function BookingTBD(){
    return(<>
          <section>
        <div className="block">
          <div className="textBlock">
            <div>
            <Rooms/>
            </div>

            <br />

            <h1></h1>
            <br />
            {/* <BookingForm /> */}
            No bookings yet
            <br />
          </div>
        </div>
      </section>Í
    </>)
}

export default BookingTBD