import Carousel from "react-material-ui-carousel";
import { Card, Paper, Typography } from "@mui/material";
import { CarouselImageItem } from "../config/types";

function Item({ item, height, width }: { item: CarouselImageItem, height?: number, width?:number }) {
  return (
    <Card>
      <img style={{ height: height, width: width }} src={item.src}></img>
      <Typography align="center">{item.text}</Typography>
    </Card>
  );
}

function ImageCarousel({CarouselItems, height = 600, width = 920}: {CarouselItems: CarouselImageItem[], height?: number, width?:number}) {
  return (
    <>
      <Carousel className="carousel">
        {CarouselItems.map((item, i) => (
          <Item key={i} item={item} height={height} width={width} />
        ))}
      </Carousel>
    </>
  );
}

export default ImageCarousel;
