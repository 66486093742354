import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BookingStep1 from "./bookingForm/bookingStep1";
import BookingStep3 from "./bookingForm/bookingStep3";
import BookingStep2 from "./bookingForm/bookingStep2";
import { useDispatch, useSelector } from "react-redux";
import { curStep, successBooking } from "../config/types";
import booking, { BookingActions } from "../utils/store/booking";
import Stripe from "./booking/stripe";
import stepper, { StepperActions } from "../utils/store/stepper";
import { toast } from "react-toastify";
import moment, { Moment } from "moment";
import { useEffect } from "react";
import i18next from "i18next";

const steps = [
  i18next.t("TXT028"),
  i18next.t("TXT029"),
  i18next.t("TXT030"),
  i18next.t("TXT031"),
];

type bookSteps = {
  stepName: string;
  content: JSX.Element;
};

export default function BookingForm() {
  const dispatch = useDispatch();
  let bookingStatus: successBooking;
  let stepperStatus: curStep;

  const today = moment();
  const [bookingStatusUpdated, setBookingStatusUpdated] = React.useState(false);
  const [isFunctionExecuted, setIsFunctionExecuted] = React.useState(false);

  let comp: null | 'A' | 'B' | 'AB' = null

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  stepperStatus = useSelector((state: any) => {
    console.log(state);
    return state.stepper;
  });

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  const updateComp = (s: "A" | "B" | "AB") => {
    console.log(s);
    comp = s
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (stepperStatus.step === 0) {
      setIsFunctionExecuted(true)
      dispatch(BookingActions.updateDates({'startDate': null, 'endDate': null}))
      console.log(bookingStatus.Compartment);
      setIsFunctionExecuted(false)
      if (comp == undefined) {
          toast.error("Please select an option");
          return;
      }
    }
    if (stepperStatus.step === 1) {
      const diffInDays = bookingStatus.endDate.diff(
        bookingStatus.startDate,
        "days"
      );

      if (diffInDays < 3) {
        toast.error("Please book for more than 2 days");
        return;
      }
    }
    if (stepperStatus.step === 2) {
      dispatch(BookingActions.updateSubmitted(true));
      if (
        bookingStatus.firstName &&
        bookingStatus.lastName &&
        bookingStatus.Email &&
        bookingStatus.Guests &&
        bookingStatus.agreeRules
      ) {
        dispatch(StepperActions.updateStep(stepperStatus.step + 1));

        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
      } else return;
    }
    dispatch(StepperActions.updateStep(stepperStatus.step + 1));
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const childRef = React.useRef<any>();


  const getStepContent = (step: number) => {
    console.log(step);
    switch (step) {
      case 0:
        return (
          <BookingStep1
          updateCompartment={updateComp}
            onClick={handleNext}
          ></BookingStep1>
        );
      case 1:
        return <BookingStep2 isFunctionExecuted={isFunctionExecuted} />;
      case 2:
        return <BookingStep3 />;
      default:
        return (
          <Stripe
            startDate={bookingStatus.startDate}
            endDate={bookingStatus.endDate}
          />
        );
    }
  };

  const handleBack = () => {
    dispatch(StepperActions.updateStep(stepperStatus.step - 1));

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={stepperStatus.step}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {stepperStatus.step === steps.length + 1 ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>{getStepContent(stepperStatus.step)}</div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={stepperStatus.step === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            {stepperStatus.step === steps.length ? (
              <> </>
            ) : (
              <>
                <Button onClick={handleNext}>Next</Button>
              </>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
