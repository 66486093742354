export type BookingItem = {
  image: string | null;
  text: string;
  compartment:  "A" | "B" | "AB";
};

export const BookingItems: BookingItem[] = [
  { image: null, text: "Deel A", compartment: 'A' },
  { image: null, text: "Deel B", compartment: 'B' },
  { image: null, text: "Heel de villa", compartment: 'AB' },
];
